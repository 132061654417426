import React from 'react';
import { Link } from "gatsby"

import topics from "../topics/topics.js";
import TopicsManager from "../topics/topicsManager.js";
import FirebaseManager from '../firebase/firebaseManager.js';
import actionItemsList from '../actionItems/actionItems.js';
import articleProblemsList from '../articleProblems/articleProblems.js';
import "./ArticlesSidebar.css"

import "./MainMenu.css"

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToLink = React.createRef();
    this.state = {completedArticles: this.getCompletedArticles()};
  }

  progressDataUpdated = () => {
    this.setState({
      completedArticles: this.getCompletedArticles()
    });
  }

  componentDidMount() {
    FirebaseManager.subscribe(this.progressDataUpdated);
  }

  componentWillUnmount() {
    FirebaseManager.unsubscribe(this.progressDataUpdated);
  }

  getCompletedArticles() {
    return TopicsManager.getAllArticles().filter((article) => {
      let actionItems = actionItemsList
        .find((articleItems) => articleItems.article === article.name)?.actionItems || [];
      let articleProblems = articleProblemsList
        .find((articleProblems) => articleProblems.article === article.name)?.problems || [];
      
      const actionItemsNum = actionItems.length;
      const articleProblemsNum = articleProblems.length;
      if (actionItemsNum + articleProblemsNum === 0)
        return false;

      return actionItems.filter((item) => FirebaseManager.getCompletedActionItems().indexOf(item.id) > -1).length + 
             articleProblems.filter((problem) => FirebaseManager.getCompletedArticleProblems().indexOf(problem) > -1).length 
             === actionItemsNum + articleProblemsNum;
    });
  }

  render() {

    const totalTasksNum = actionItemsList.reduce((total, item) => total + item.actionItems.length, 0) + 
                          articleProblemsList.reduce((total, item) => total + item.problems.length, 0);
    const totalCompletedTasksNum = FirebaseManager.getCompletedActionItems().length + 
                                   FirebaseManager.getCompletedArticleProblems().length;

    const allProblems = articleProblemsList.reduce((total, item) => total.concat(item.problems), []);
    
    return (
      <div>
        <div className="flex w-full content-center justify-center">
          {/* <img className="h-16 w-16 md:h-20 md:w-20 lg:h-24 lg:w-24 mr-4 mt-2 md:mt-2 lg:mt-4 md:mr-6 opacity-75" src="/wreath.svg"/> */}
          <div>
            <h1 className="text-center text-4xl mt-1 md:mt-0 md:text-5xl lg:text-6xl font-bold text-gray-800">Interviews<span className="text-blue-500">.school</span></h1>
            <h2 className="text-center text-lg md:text-xl lg:text-2xl text-gray-500 font-medium -mt-2 mb-12 md:mb-20">Complete interview preparation guide</h2>
          </div>
        </div>
        {/* <h2 className="text-center text-lg md:text-xl lg:text-2xl text-gray-500 font-medium mt-4 mb-16 md:mb-24">Complete interview preparation guide</h2> */}
        <div className="flex content-center justify-center mt-2 md:mt-0 mb-6 md:mb-10">
          <h3 className="font-medium text-center text-gray-500 mt-2">
            <span className="">{Math.floor(10000.0 * totalCompletedTasksNum / totalTasksNum) / 100}%</span> complete &nbsp;•&nbsp;&nbsp;{totalCompletedTasksNum}/{totalTasksNum} tasks done
          </h3>
        </div>
        <div className="pt-2 md:pt-0 pb-6 w-full rounded-2xl bg-opacity-75 border-gray-300">
          <div className="max-w-4xl mx-auto">
            {
              topics.map((topic, idx) => (
                  <div key={topic.title}>
                    {
                      topic.articles.length > 12 && 
                      <div>
                        <h1 className="text-xl text-center font-medium text-gray-800 mt-1 mr-5 mb-8 md:mr-8">
                          {topic.title}
                        </h1>
                        <div className="mx-auto flex justify-evenly mb-16">
                          <div className="md:ml-6 mm-bullets flex flex-col">
                            {
                              topic.articles.slice(0, 12).map((article, idx) => (
                                <Link to={'/' + article.name} key={article.title} className={(this.state.completedArticles.indexOf(article) > -1 ? 'mm-bullet-done ' : '') + 'mm-bullet text-gray-700 pt-2 pb-2 rounded-md pr-8 mr-2 pl-12 hover:text-gray-900 md:text-lg hover:bg-blue-100 transition easy-in-out duration-100'}>
                                  {article.title}
                                </Link>
                              ))
                            }
                          </div>
                          <div className="mm-bullets flex flex-col">
                            {
                              topic.articles.slice(12, 24).map((article, idx) => (
                                <Link to={'/' + article.name} key={article.title} className={(this.state.completedArticles.indexOf(article) > -1 ? 'mm-bullet-done ' : '') + 'mm-bullet text-gray-700 pt-2 pb-2 rounded-md pr-8 mr-2 pl-12 hover:text-gray-900 md:text-lg hover:bg-blue-100 transition easy-in-out duration-100'}>
                                  {article.title}
                                </Link>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    }
                    {
                      topic.articles.length <= 12 && 
                      <div className="flex mb-12">
                        <div className="w-2/5 md:w-1/2 text-right">
                          <h1 className="text-xl text-right font-medium text-gray-800 mt-1 mr-5 md:mr-8">
                            {idx === 0 ?
                              <span className="text-blue-500">Start here</span> :
                              topic.title
                            }
                          </h1>
                        </div>
                        <div className="mm-bullets flex flex-col w-3/5 md:w-1/2">
                          {
                            topic.articles.map((article, idx) => (
                              <Link to={'/' + article.name} key={article.title} className={(this.state.completedArticles.indexOf(article) > -1 ? 'mm-bullet-done ' : '') +  'mm-bullet text-gray-700 pt-2 pb-2 rounded-md pr-8 mr-2 pl-12 hover:text-gray-900 md:text-lg hover:bg-blue-100 transition easy-in-out duration-100'}>
                                {article.title}
                              </Link>
                            ))
                          }
                        </div>
                      </div>
                    }
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default MainMenu;