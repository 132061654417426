import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainMenu from "../components/MainMenu"

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="-mt-20 pt-24 md:pt-24 lg:pt-32">
        <div className="flex h-full w-full text-white"> 
          <svg style={{zIndex: '-5'}} className="fixed top-0 left-0 transform -translate-x-48 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784"><defs><pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" fill="#EDF2F7"></rect></pattern></defs><rect y="0" width="200" height="640" fill="#ffffff"></rect><rect x="118" width="200" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"></rect></svg>
        </div>
        <div className="md:max-w-6xl mx-auto">
          <MainMenu />
          <center>
            <img className="w-3/5 md:w-2/5 lg:w-2/5 mt-4 md:mt-10 lg:mt-10 transition ease-in-out duration-300 transform hover:scale-105" src="/developer.svg" />
          </center>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
